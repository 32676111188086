import React from 'react';
 import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Login from './LoginPage';
import ImageGallery from './Ring';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Layout from "./Layout";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<Layout />}>
        <Route path="/" element={<Login />} />
        <Route path="/Ring" element={<ImageGallery />} />
      </Route>
    </Routes>
  </BrowserRouter>
);

reportWebVitals();