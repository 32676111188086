import React, { useState } from 'react';
import img1 from './Assets/img/img1.jpg';
import { Link } from 'react-router-dom';
const Login = () => {
  const [username, setUsername] = useState('');
  const [mobileNumber, setMobileNumber] = useState('');
  const [email, setEmail] = useState('');
  const [isLoginDisabled, setLoginDisabled] = useState(true);

  const handleUsernameChange = (e) => {
    const inputUsername = e.target.value;
    setUsername(inputUsername);

    // Validate if username contains only alphabets
    const usernameRegex = /^[A-Za-z]+$/;
    setLoginDisabled(!usernameRegex.test(inputUsername) || mobileNumber.length !== 10 || !isValidEmail(email));
  };

  const handleMobileNumberChange = (e) => {
    const inputMobileNumber = e.target.value;
    setMobileNumber(inputMobileNumber);

    // Validate if mobile number is exactly 10 digits
    const mobileRegex = /^\d{10}$/;
    setLoginDisabled(!mobileRegex.test(inputMobileNumber) || !username || !isValidEmail(email));
  };

  const handleEmailChange = (e) => {
    const inputEmail = e.target.value;
    setEmail(inputEmail);

    // Validate if email is in proper format
    setLoginDisabled(!isValidEmail(inputEmail) || mobileNumber.length !== 10 || !username);
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[A-Za-z0-9._%+-]+@gmail\.com$/;
    return emailRegex.test(email);
  };

  const handleLogin = () => {
    // Rest of your login logic here
  };
  
  return (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        background: '#FFC0CB', // Background color for the entire screen
      }}
    >
      {/* Login form */}
      <div style={{ backgroundColor: 'pink', padding: '20px', borderRadius: '10px', width: '60%' }}>
        {/* Background image */}
        <div
          style={{
            backgroundImage: `url(${img1})`,
            backgroundSize: 'cover',
            backgroundPosition: 'center',
            width: '100%', // Full width
            height: '50vh', // Adjust the height as needed
            borderRadius: '5px',
            marginBottom: '20px',
          }}
        ></div>

      
<h2 style={{ textAlign: 'center', marginBottom: '20px', color: '#000000' }}>Fill The Details</h2>
        <form style={{ display: 'flex', flexDirection: 'column' }}>
          <label style={{ marginBottom: '10px', color: '#000000' }}>Username:</label>
          <input
            type="text"
            value={username}
            placeholder='example :- xyz'
            onChange={handleUsernameChange}
            style={{ padding: '10px', marginBottom: '20px', borderRadius: '5px', color: '#000000' }}
          />

          <label style={{ marginBottom: '10px', color: '#000000'}} >Email:</label>
          <input
            type="email"
            value={email}
            placeholder='example :- xyz@gmail.com'
            onChange={handleEmailChange}
            style={{ padding: '10px', marginBottom: '20px', borderRadius: '5px', color: '#000000' }}
          />

          <label style={{ marginBottom: '10px', color: '#000000' }}>Mobile Number:</label>
          <input
            type="tel"
            value={mobileNumber}
            onChange={handleMobileNumberChange}
            placeholder='example :- 1234567890'
            style={{ padding: '10px', marginBottom: '20px', borderRadius: '5px', color: '#000000' }}
          />
 
          <Link to="/Ring">
            <button
              type="button"
              onClick={handleLogin}
              style={{
                padding: '10px',
                borderRadius: '5px',
                backgroundColor: isLoginDisabled ? 'grey' : '#4285f4',
                color: '#ffffff',
                cursor: isLoginDisabled ? 'not-allowed' : 'pointer',
                transition: 'background-color 0.3s',
                width: '100%', // Full width
              }}
              // Hover effect
              onMouseOver={(e) => (!isLoginDisabled && (e.target.style.backgroundColor = '#2c5db2'))}
              onMouseOut={(e) => (!isLoginDisabled && (e.target.style.backgroundColor = '#4285f4'))}
              disabled={isLoginDisabled}
            >
              Login
            </button>
          </Link>
        </form>
      </div>
    </div>
  );
};

export default Login;
